* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Poppins", cursive;
  font-weight: 400;
  font-style: normal;
  outline-color: #15803d;
}

.marquee {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  line-height: 25px;
  background-color: rgb(0, 0, 0);
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 1000;
}

.heading-2 {
  font-family: "Fascinate Inline", system-ui;
  font-weight: 400;
  font-style: normal;
}

.mainTitle {
  font-family: "Protest Guerrilla", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 50s linear infinite;
}

.logo {
  height: 150px;
  width: 150px;
  background-image: url("./assets/mainLogo.jpeg");
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  margin: 73px auto;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

::-webkit-scrollbar {
  display: none;
}
