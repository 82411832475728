@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cleanBtn {
  min-height: 25px;
  min-width: 25px;
  background-image: url("./assets/cleanBtn.png");
  background-position: center;
  background-size: cover;
}

@keyframes flash {
  0% {
    opacity: 1;
    height: 0px;
    width: 0px;
  }
  100% {
    height: 100px;
    width: 100px;
    opacity: 0;
  }
}
